import React from "react";
import Ashok from "../assets/img/Ashok.webp";
import AshokG from "../assets/img/AshokG.webp";
import Sanjay from "../assets/img/Sanjay.webp";
import Puneet from "../assets/img/Puneet.webp";
import Bharati from "../assets/img/Bharati.webp";
import Ferik from "../assets/img/Ferik.webp";
import Chirag from "../assets/img/Chirag.webp";
import Aditya from "../assets/img/Aditya.webp";
import Chikitsa from "../assets/img/Chikitsa.webp";
import Narendra from "../assets/img/Narendra.webp";
import Jagdish from "../assets/img/Jagdish.webp";
import Nayan from "../assets/img/Nayan.webp";
import Samarth from "../assets/img/Samarth.webp";
import Madhvi from "../assets/img/Madhvi.webp";
import Sonal from "../assets/img/Sonal.webp";
import Manvi from "../assets/img/Manvi.webp";
import Nirav from "../assets/img/Nirav.webp";
// import Yaksh from "../assets/img/Yaksh.webp";
import Aneri from "../assets/img/Aneri.webp";
// import Manoj from "../assets/img/Manoj.webp";

export const WhoWeAre = () => {
  const imageInfo = [
    {
      imageSrc: Ashok,
      name: "Ashok",
    },
    {
      imageSrc: AshokG,
      name: "Ashok",
    },
    {
      imageSrc: Sanjay,
      name: "Sanjay",
    },
    {
      imageSrc: Puneet,
      name: "Puneet",
    },
    {
      imageSrc: Bharati,
      name: "Bharati",
    },
    {
      imageSrc: Ferik,
      name: "Ferik",
    },
    {
      imageSrc: Chirag,
      name: "Chirag",
    },
    {
      imageSrc: Aditya,
      name: "Aditya",
    },
    {
      imageSrc: Narendra,
      name: "Narendrasinh",
    },
    {
      imageSrc: Jagdish,
      name: "Jagdish",
    },
    {
      imageSrc: Nayan,
      name: "Nayan",
    },
    {
      imageSrc: Madhvi,
      name: "Madhvi",
    },
    {
      imageSrc: Chikitsa,
      name: "Chikitsa",
    },
    {
      imageSrc: Samarth,
      name: "Samarth",
    },
    // {
    //   imageSrc: Yaksh,
    //   name: "Yaksh",
    // },
    {
      imageSrc: Aneri,
      name: "Aneri",
    },
    // {
    //   imageSrc: Manoj,
    //   name: "Manoj",
    // },
    {
      imageSrc: Sonal,
      name: "Sonal",
    },
    {
      imageSrc: Manvi,
      name: "Manvi",
    },
    {
      imageSrc: Nirav,
      name: "Nirav",
    },
  ];

  return (
    <section id="who-we-are">
      <div class="container">
        <header class="section-header text-left" data-aos="fade-up">
          <h2>Who We Are</h2>
          <p>
            Our team is headed by a group of veteran fintech professionals who have built, run and managed complete front to back global financial market data, analytics, trading, straight through
            processing and reporting systems. The systems include various asset classes such as Equities, Foreign Exchange, Interest Rate, Credit, Fixed Income, Commodities, Energy and other esoteric
            asset classes and exotic products.
            <br />
            <br />
            Thousands of high value transaction, worth multi trillions of dollars, have been executed on the systems we have built. These transactions were executed by major banks and global financial
            institutions in cities across the globe, such as New York, London, Singapore and Japan.
            <br />
            <br />
            The founder of the company, Ashok Mittal, has more than three decades of technology and financial industry experience. Our ever growing team consists of both fintech industry experts and
            young professionals. The partners of the firm are all veterans of the fintech industry.
          </p>
        </header>
        <div className="row row-cols-md-4 row-cols-lg-6 row-cols-sm-3 row-cols-3 g-4 mx-lg-6 justify-content-center">
          {imageInfo.map((info, index) => (
            <div key={index} className="col col-lg-1 px-3" data-aos="fade-up" data-aos-delay={50 + index * 50}>
              <div className="card border-0 text-center">
                <img src={info.imageSrc} alt={`${info.name}`} className="card-img-top rounded" />
                <div className="card-body m-1 p-0">
                  <div className="card-title image-name">{info.name}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
