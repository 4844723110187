import React, { useState, useEffect, useMemo, useRef } from "react";
import RightArrow from "../assets/img/RightArrow.webp";
import LeftArrow from "../assets/img/LeftArrow.webp";
// Import images
import Gallery0 from "../assets/img/Gallery0.webp";
import Gallery1 from "../assets/img/Gallery1.webp";
import Gallery2 from "../assets/img/Gallery2.webp";
import Gallery3 from "../assets/img/Gallery3.webp";
import Gallery4 from "../assets/img/Gallery4.webp";
import Gallery5 from "../assets/img/Gallery5.webp";
import Gallery6 from "../assets/img/Gallery6.webp";
import Gallery7 from "../assets/img/Gallery7.webp";
import Gallery8 from "../assets/img/Gallery8.webp";
import Gallery9 from "../assets/img/Gallery9.webp";
import Gallery10 from "../assets/img/Gallery10.webp";
import Gallery11 from "../assets/img/Gallery11.webp";
import Gallery12 from "../assets/img/Gallery12.webp";
import Gallery13 from "../assets/img/Gallery13.webp";
import Gallery14 from "../assets/img/Gallery14.webp";
import Gallery15 from "../assets/img/Gallery15.webp";
import Gallery16 from "../assets/img/Gallery16.webp";
import Gallery17 from "../assets/img/Gallery17.webp";
import Gallery18 from "../assets/img/Gallery18.webp";

const getNumberOfImages = () => {
  const screenWidth = window.innerWidth;

  if (screenWidth <= 2560 && screenWidth > 1920) {
    // Extra Large
    return 9;
  } else if (screenWidth <= 1920 && screenWidth > 1440) {
    // Large
    return 7;
  } else if (screenWidth <= 1440 && screenWidth > 1024) {
    // Laptop L
    return 5;
  } else if (screenWidth <= 1024 && screenWidth > 768) {
    // Laptop
    return 4;
  } else if (screenWidth <= 768 && screenWidth > 430) {
    // Tablet
    return 3;
  } else {
    // Phone
    return 1;
  }
};

const getImageStyle = (i) => {
  const screenWidth = window.innerWidth;
  const isFirst = i === 0;
  const isLast = i === getNumberOfImages() - 1;

  if (screenWidth <= 2560 && screenWidth > 1920) {
    // Extra Large
    return {
      marginLeft: isFirst ? "-25%" : 0,
      marginRight: isLast ? "-25%" : 0,
    };
  } else if (screenWidth <= 1920 && screenWidth > 1440) {
    // Large
    return {
      marginLeft: isFirst ? "-30%" : 0,
      marginRight: isLast ? "-30%" : 0,
    };
  } else if (screenWidth <= 1440 && screenWidth > 1024) {
    // Laptop L
    return {
      marginLeft: isFirst ? "-25%" : 0,
      marginRight: isLast ? "-25%" : 0,
    };
  } else if (screenWidth <= 1024 && screenWidth > 768) {
    // Laptop
    return {
      marginLeft: isFirst ? "-35%" : 0,
      marginRight: isLast ? "-35%" : 0,
    };
  } else if (screenWidth <= 768 && screenWidth > 430) {
    // Tablet
    return {
      marginLeft: isFirst ? "-35%" : 0,
      marginRight: isLast ? "-35%" : 0,
    };
  } else {
    // Phone
    return {
      marginLeft: isFirst ? "0%" : 0,
    };
  }
  // if (screenWidth >= 1024 && screenWidth <= 1440) {
  //   // Bigger Screens
  //   return {
  //     marginLeft: i === 0 && getNumberOfImages() - 1 > 0? "-35%" : 0,
  //     marginRight: i === getNumberOfImages() -1 ? "-35%" : 0,
  //   };
  // } else {
  //   // Smaller screens
  //   return {
  //     marginLeft: i === 0 && getNumberOfImages() - 1 > 0? "-40%" : 0,
  //     marginRight: i === getNumberOfImages() -1 ? "-40%" : 0,
  //   };
  // }
};

const getImageFrameStyle = () => {
  const screenWidth = window.innerWidth;

  if (screenWidth >= 768) {
    // Bigger Screens
    return {
      width: "406px",
      height: "300px",
    };
  } else {
    // Smaller screens
    return {
      width: "262px",
      height: "180px",
    };
  }
};

export const Gallery = () => {
  const Image = useMemo(() => [Gallery0, Gallery1, Gallery2, Gallery3, Gallery4, Gallery5, Gallery6, Gallery7, Gallery8, Gallery9, Gallery10, Gallery11, Gallery12, Gallery13, Gallery14, Gallery15, Gallery16, Gallery17, Gallery18], []);

  const activeIndexRef = useRef(0);
  const screenWidthRef = useRef(window.innerWidth);
 
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePrevClick = () => {
    setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : Image.length - 1));
  };

  const handleNextClick = () => {
    setActiveIndex((prevIndex) => (prevIndex < Image.length - 1 ? prevIndex + 1 : 0));
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex < Image.length - 1 ? prevIndex + 1 : 0));
    }, 3000);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [Image.length]); // Only re-run the effect if Image.length changes

  useEffect(() => {
    const handleResize = () => {
      const newScreenWidth = window.innerWidth;
      if (newScreenWidth !== screenWidthRef.current) {
        screenWidthRef.current = newScreenWidth;
        setActiveIndex(activeIndexRef.current);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Only run on mount and unmount

  return (
    <section id="gallery">
      <header className="section-header text-center" data-aos="fade-left">
        <h2>Gallery</h2>
      </header>
      <div className="">
        <div className="carousel-container">
          <div className="carousel slide" data-bs-ride="carousel" data-aos="fade-left">
            {/* Carousel items */}
            <div className="carousel-inner mb-5">
              {Image.map((imageUrl, index) => {
                const isActive = index === activeIndex;

                return isActive ? (
                  <div className={`carousel-item active row d-flex flex-nowrap ps-3`} key={index}>
                    {Array.from({ length: getNumberOfImages() }).map((_, i) => {
                      const imageIndex = (activeIndex + i) % Image.length;
                      return (
                        <div className={`col d-flex justify-content-center`} key={i} style={getImageStyle(i)}>
                          <img loading="lazy" src={Image[imageIndex]} alt={` ${imageIndex + 1}`} className="object-fit-cover rounded-5 border border-dark bg-white" style={getImageFrameStyle()} />
                        </div>
                      );
                    })}
                  </div>
                ) : null;
              })}
            </div>

            {/* Carousel controls */}
            {/* Custom Carousel controls */}
            <div className="row justify-content-center mt-5">
              <div className="col-auto">
                <img src={LeftArrow} alt="Previous" className="custom-carousel-control prev" onClick={handlePrevClick} />
              </div>
              <div className="col-auto">
                <img src={RightArrow} alt="Next" className="custom-carousel-control next" onClick={handleNextClick} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
