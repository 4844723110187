import { React } from "react";
import { NavBar } from "../components/Navbar";
import { Banner } from "../components/Banner";
import { TradingSystem } from "../components/TradingSystem";
import { Footer } from "../components/Footer";
import { HowWeEngage } from "../components/HowWeEngage";
import { WhatWeDo } from "../components/WhatWeDo";
import { WhoWeAre } from "../components/WhoWeAre";
import { WhyChooseUs } from "../components/WhyChooseUs";
import { WhatWeBuild } from "../components/WhatWeBuild";
import { Contact } from "../components/Contact";
import logoBlack from "../assets/img/logo-black.svg";
import { Gallery } from "../components/Gallery";
import { Helmet } from 'react-helmet';

export default function Landing() {
  return (
    <>
      <Helmet>
        <title>Home - Fintech Global Center</title>
        <meta name="description" content="Explore finance and tech convergence at Fintech Global Center. Discover innovative solutions and trends. Join us to redefine finance." />
        <link rel="canonical" href="https://www.fintechglobal.center/" />
      </Helmet>
      <NavBar logo={logoBlack} />
      <Banner />
      <WhatWeDo />
      <WhatWeBuild />
      <TradingSystem />
      <WhyChooseUs />
      <HowWeEngage />
      <WhoWeAre />
      <Gallery />
      <Contact />
      <Footer />
    </>
  );
}
