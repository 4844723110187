import { React } from "react";
import { Footer } from "../components/Footer";
import { Helmet } from 'react-helmet';
import { NavBar } from "../components/Navbar";
import logoBlack from "../assets/img/logo-black.svg";
import { ProjectList } from "../components/ProjectsList";

export default function OurProjects() {
  return (
    <>
      <Helmet>
        <title>Projects - Fintech Global Center</title>
        <meta name="description" content="Explore our fintech projects at Fintech Global Center, from innovative solutions to industry advancements. Join our finance revolution." />
        <link rel="canonical" href="https://www.fintechglobal.center/projects" />
      </Helmet>
      <NavBar logo={logoBlack} />
      <ProjectList />
      <Footer />
    </>
  );
}
