import React, { useState } from "react";
import { Card } from "./ProjectCard";

export const ProjectList = () => {
  // Project list state
  const projectList = [
    // {
    //   id: 12,
    //   Name: "How to succeed as a Fintech Entrepreneur?",
    //   Link: "/projects/how-to-succeed-as-a-fintech-entrepreneur",
    //   associatedFilters: ["Article"],
    //   isFilterActive: false,
    // },
    {
      id: 0,
      Name: "Cloud Based Fixed Income Trading System",
      Link: "/projects/cloud-fixed-income-trading-system",
      associatedFilters: ["Fixed Income"],
      isFilterActive: false,
    },
    {
      id: 1,
      Name: "Comprehensive Fixed Income Market Data and Analytics System",
      Link: "/projects/comprehensive-fixed-income-system",
      associatedFilters: ["Fixed Income"],
      isFilterActive: false,
    },
    {
      id: 2,
      Name: "Fixed Income Electronic Post Trade Processing System",
      Link: "/projects/electronic-post-trade-processing-system",
      associatedFilters: ["Fixed Income"],
      isFilterActive: false,
    },
    {
      id: 3,
      Name: "Consolidated Tape and Analytics for Fixed Income Trades",
      Link: "/projects/consolidated-tape-and-analytics",
      associatedFilters: ["Fixed Income"],
      isFilterActive: false,
    },
    {
      id: 4,
      Name: "Retail Bond Trading System",
      Link: "/projects/retail-bond-trading",
      associatedFilters: ["Fixed Income"],
      isFilterActive: false,
    },
    {
      id: 5,
      Name: "Lite Equity Order Management System (OMS)",
      Link: "/projects/lite-equity-oms",
      associatedFilters: ["Equities"],
      isFilterActive: false,
    },
    // {
    //   id: 6,
    //   Name: "Global Corporate Action System for Prime Broker Services",
    //   Link: "/projects/corporate-action-management",
    //   associatedFilters: ["Equities", "Fixed Income"],
    //   isFilterActive: false,
    // },
    {
      id: 12,
      Name: "How to succeed as a Fintech Entrepreneur?",
      Link: "/projects/how-to-succeed-as-a-fintech-entrepreneur",
      associatedFilters: ["Article"],
      isFilterActive: false,
    },
    {
      id: 7,
      Name: "Global Corporate Action Event Generation & Reporting System",
      Link: "/projects/corporate-action-events",
      associatedFilters: ["Equities", "Fixed Income"],
      isFilterActive: false,
    },
    {
      id: 8,
      Name: "Global Multi-Asset Class Trade Management System",
      Link: "/projects/multi-asset-trade-management-system",
      associatedFilters: ["Equities", "FX", "Fixed Income"],
      isFilterActive: false,
    },
    {
      id: 9,
      Name: "Global Asset Class Neutral Trading System",
      Link: "/projects/trade-management-system",
      associatedFilters: ["Equities", "FX", "Fixed Income", "Interest Rate"],
      isFilterActive: false,
    },
    {
      id: 10,
      Name: "Advanced NDF, FX, FX Options, and Interest Rate Trading Systems",
      Link: "/projects/advanced-trading-systems",
      associatedFilters: ["FX", "Fixed Income", "Interest Rate"],
      isFilterActive: false,
    },
    {
      id: 11,
      Name: "Global Inter Dealer Broker Asset Class Neutral Trading System",
      Link: "/projects/inter-dealer-broker-trading-system",
      associatedFilters: ["Equities", "FX", "Interest Rate", "Commodity", "Energy"],
      isFilterActive: false,
    },
  ];

  const [activeFilter, setActiveFilter] = useState(null);

  const toggleFilter = (filter) => {
    setActiveFilter(filter === activeFilter ? null : filter);
  };

  const filteredProjects = projectList.filter((project) => {
    return !activeFilter || project.associatedFilters.includes(activeFilter);
  });

  return (
    <>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h1>Projects</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-3">
        <div className="row mb-3">
          <div className="col">
            <div className="project-category-container" role="group">
              <div className="project-category">
                {[null, ...new Set(projectList.flatMap((p) => p.associatedFilters))].filter(filter => filter !== "Article").map((filter) => (
                  <div
                    key={filter}
                    className={`category-item ${filter === activeFilter ? "active" : ""}`}
                    onClick={() => toggleFilter(filter)}
                    style={{
                      cursor: "pointer",
                      fontWeight: filter === activeFilter ? "bold" : "normal",
                      background: filter === activeFilter ? "#C7B1FF" : "#F7F7FB",
                      borderRadius: filter === activeFilter ? "30px" : "30px",
                      color: filter === activeFilter ? "#1D2027" : "rgba(29, 32, 39, 0.80)",
                    }}
                  >
                    {filter ? filter : "All"}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 mb-5 justify-content-center">
          {filteredProjects.map((project) => (
            <div className="col" id="project-cards" key={project.id}>
              <Card {...project} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
