import fintechTradingSystemImg from "../assets/img/fintech-trading-system.webp";

export const TradingSystem = () => {
  return (
    <section id="trading-system">
      <div class="container mt-2">
        <header class="section-header text-center" data-aos="fade-up">
          <p>
            A trading system consists of various components, such as Market Data, Analytics, Trading Engine, Trading Protocols, Confirms, STP, Reg Reporting, Compliance Monitoring, etc. Many systems
            require FIX connectivity for Market data, Trading and Trade Capture Reports. A trading UI, Market making tools such as Excel and connectivity to external systems is important as well. The
            system additionally needs several middle office and back office components. A fintech system must be secure and complaint with various regulations for trading.
            <br />
            <br />
            Call us, whether you need help with one or more of these components, or need someone to build the entire system for you. We have experience in building, running and managing all of these
            components for the global fintech industry for Fixed Income, FX, Rates, Credit, Equities and other products and asset classes.
          </p>
        </header>

        <div class="trading-system-img" data-aos="zoom-out">
          <img id="trading-system-img" src={fintechTradingSystemImg} alt="Fintech Trading System" class="img-fluid" />
        </div>
        <div class="text-center" data-aos="fade-up">
          <a href="/#contact" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center mt-5">
            <span>Get in Touch</span>
          </a>
        </div>
      </div>
    </section>
  );
};
