import icon1 from "../assets/img/how-we-engage-icon-1.webp";
import icon2 from "../assets/img/how-we-engage-icon-2.webp";
import icon3 from "../assets/img/how-we-engage-icon-3.webp";

const HowWeEngageItem = ({ icon, title, description }) => {
  return (
    <div className="col-lg-4 col-md-6" data-aos="zoom-in">
      <div className="how-we-engage-item position-relative">
        <div className="icon">
          <i>
            <img src={icon} alt="icon"></img>
          </i>
        </div>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export const HowWeEngage = () => {
  return (
    <section id="how-we-engage" className="how-we-engage sections-bg">
      <div className="container" data-aos="fade-up">
        <header className="section-header text-center">
          <h2>How We Engage</h2>
        </header>
        <div className="row gy-4 justify-content-center" data-aos="fade-up" data-aos-delay="100">
          <HowWeEngageItem
            icon={icon1}
            title="We can be an extension to your IT"
            description="With highly skilled fintech expert team, we can work as an extension of your IT team, whether you need Development Services, DevOps, Sysadmin, Operations, Maintenance, or Support services."
          />
          <HowWeEngageItem
            icon={icon2}
            title="We can do project that you need to get done, now"
            description="In case you have a standalone project which can be integrated in your existing system or otherwise, we can do the whole project including architecture, design and implementation."
          />
          <HowWeEngageItem
            icon={icon3}
            title="We can be your IT"
            description="With a full function dev, qa, ops and 24x7 support services team, we can be your IT. Yes, we do this successfully for young entrepreneurial fintechs as well as large financial institutions for lines of their businesses. The services include client onboarding, operations, engineering support as well."
          />
        </div>
      </div>
      <div class="text-center" data-aos="fade-up">
        <a href="/#contact" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center mt-5">
          <span>Get in Touch</span>
        </a>
      </div>
    </section>
  );
};

export default HowWeEngage;
