import { React, useState, useRef } from "react";
import india from "../assets/img/india.webp";
import usa from "../assets/img/usa.webp";
import { Telephone } from "react-bootstrap-icons";
import { Envelope } from "react-bootstrap-icons";
import { GeoAlt } from "react-bootstrap-icons";

export const Contact = () => {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const [sendStatus, setSendStatus] = useState("not-submitted");
  const formRef = useRef();

  const sendMessage = (event) => {
    console.log("sending message", name, email, message);
    event.preventDefault();
    if (name && email && message) {
      console.log(name, email, message);
      setSendStatus("submitted");
      fetch("https://now02kehwj.execute-api.us-east-1.amazonaws.com/dev/fintech-submit", {
        method: "POST",
        body: JSON.stringify({
          name: name,
          email: email,
          message: message,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res)
        .then((response) => {
          setSendStatus("sent");
          formRef.current.reset();
        })
        .catch((err) => {
          setSendStatus("error");
          formRef.current.reset();
        });
    }
  };

  return (
    <section id="contact" class="contact section-bg">
      <div class="container">
        <div class="row">
          <div id="contact-form" class="col-lg-7 mt-5 mt-lg-0" data-aos="fade-left">
            <form
              ref={formRef}
              method="post"
              class="php-email-form pr-lg-5"
              onSubmit={(e) => {
                sendMessage(e);
              }}
            >
              <div class="row">
                <header class="section-header text-left" data-aos="fade-up">
                  <h2>Love to hear from you.</h2>
                  <p>
                    No project is too big or too small. Contact us for your fintech needs today! <br />
                    <br />
                    We are growing. Be a part of the Fintech Global Center Team in New York, USA or GIFT City, India
                  </p>
                </header>

                <div class="form-group mt-3">
                  <label for="name">Your Name</label>
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    id="name"
                    placeholder="Enter your name"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    required
                  />
                </div>
              </div>
              <div class="form-group mt-3">
                <label for="name">Email</label>
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                />
              </div>
              <div class="form-group mt-3">
                <label for="name">Message</label>
                <textarea
                  class="form-control"
                  name="message"
                  rows="9"
                  placeholder="Write here..."
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  required
                ></textarea>
              </div>

              {sendStatus === "not-submitted" ? (
                <>
                  <button type="submit" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center mt-3 w-100">
                    <span>Get in Touch</span>
                  </button>
                </>
              ) : sendStatus === "submitted" ? (
                <div class="loading mt-3">Sending Message...</div>
              ) : sendStatus === "sent" ? (
                <div class="sent-message mt-3">Your message has been sent. Thank you!</div>
              ) : sendStatus === "error" ? (
                <div class="error-message mt-3"> Oops, Something went wrong! Please try again later!</div>
              ) : null}
            </form>
          </div>

          <div id="contact-info" class="col-lg-5" data-aos="fade-right">
            <div class="info mb-3">
              <div id="location" className="d-flex align-items-center">
                <img src={usa} alt="Icon" />
                <h3>USA</h3>
              </div>

              <div>
                <i class="bi bi-envelope">
                  <Envelope size={20} />
                </i>
                <p>contact@fintechglobal.center</p>
              </div>

              <div>
                <i class="bi bi-phone">
                  <Telephone size={20} />
                </i>
                <p>+1-646-688-7500</p>
              </div>

              <div>
                <i class="bi bi-phone">
                  <GeoAlt size={20} />
                </i>
                <p className="mb-0">
                  101 Crawfords Corner Rd, Suite 4116
                  <br /> Holmdel, NJ 07733
                </p>
              </div>
            </div>
            <div class="info">
              <div id="location" className="d-flex align-items-center">
                <img src={india} alt="Icon" />
                <h3>INDIA</h3>
              </div>

              <div>
                <i class="bi bi-envelope">
                  <Envelope size={20} />
                </i>
                <p>contact@fintechglobal.center</p>
              </div>

              <div>
                <i class="bi bi-phone">
                  <Telephone size={20} />
                </i>
                <p>+91-966-212-0909</p>
              </div>

              <div>
                <i class="bi bi-phone">
                  <GeoAlt size={20} />
                </i>
                <p className="mb-0">
                  9th Floor, GIFT One,
                  <br /> Gujarat International Finance Tec-City,
                  <br /> Gandhinagar, Gujarat 382355
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
