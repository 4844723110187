import React, { useEffect, useState } from "react";
import PrimeBroker from "../assets/img/prime-broker-bg.webp";
import AdvancedNDF from "../assets/img/advanced-trading-bg.webp";
import CloudBased from "../assets/img/cloud-based-bg.webp";
import ComprehensiveFixed from "../assets/img/comprehensive-fixed-bg.webp";
import DealerBroker from "../assets/img/inter-dealer-bg.webp";
import ElectronicTrade from "../assets/img/electronic-trade-bg.webp";
import MultiAsset from "../assets/img/multi-asset-trade-bg.webp";
import NeutralTrade from "../assets/img/neutral-trading-bg.webp";
import LiteEquity from "../assets/img/lite-equity-bg.webp";
import RetailBond from "../assets/img/retail-bond-bg.webp";
import TapeAnalytics from "../assets/img/consolidated-tape-bg.webp";
import ActionEvent from "../assets/img/action-event-bg.webp";
import Entrepreneur from "../assets/img/fintech-entrepreneur-bg.webp";

export const Card = ({ associatedFilters, Name, Link, id }) => {
  const [image, setImage] = useState(null);
  const projectList = [
    {
      id: 0,
      Image: CloudBased,
    },
    {
      id: 1,
      Image: ComprehensiveFixed,
    },
    {
      id: 2,
      Image: ElectronicTrade,
    },
    {
      id: 3,
      Image: TapeAnalytics,
    },
    {
      id: 4,
      Image: RetailBond,
    },
    {
      id: 5,
      Image: LiteEquity,
    },
    // {
    //   id: 6,
    //   Image: PrimeBroker,
    // },
    {
      id: 7,
      Image: ActionEvent,
    },
    {
      id: 8,
      Image: MultiAsset,
    },
    {
      id: 9,
      Image: NeutralTrade,
    },
    {
      id: 10,
      Image: AdvancedNDF,
    },
    {
      id: 11,
      Image: DealerBroker,
    },
    {
      id: 12,
      Image: Entrepreneur
    },
  ];

  useEffect(() => {
    const project = projectList.find((project) => project.id === id);
    if (project) {
      setImage(project.Image);
    }
  }, []);

  return (
    <a href={Link} className="text-decoration-none">
      <div className="card h-100 bg-white" id="project-list-card" style={{ backgroundImage: `url(${image})`, backgroundSize: "cover", backgroundPosition: "right", minHeight: "220px" }}>
        {/* {Image && <img src={Image} className="card-img-top" style={{ borderRadius: "30px 30px 0px 0px" }} alt={Name} />} */}
        <div className="card-body d-flex flex-column justify-content-between">
          <div className="card-title mb-3">
            <div className="category-list" style={{ padding: "0px" }}>
              {associatedFilters.map((filter, index) => (
                <div className="category-list-item" key={index}>
                  {filter}
                </div>
              ))}
            </div>
          </div>
          <div className="card-text text-dark category-name" style={{ fontWeight: "700", paddingLeft: "4px", fontSize: "20px" }}>
            {Name}
          </div>
          <div className="card-link text-dark mt-4 align-self-start text-dark" id="project-list-link" aria-label={`Read more about ${Name}`}>
            Read More{">>>>"}
          </div>
        </div>
      </div>
    </a>
  );
};
