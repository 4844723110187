import { useState, useEffect, useRef } from "react";
import { Navbar, Nav } from "react-bootstrap";
import logo from '../assets/img/logo-black.svg';

export const NavBar = ({ logo }) => {
  const [activeLink, setActiveLink] = useState('');
  const currentPath = window.location.pathname; // Get the current path
  const [expanded, setExpanded] = useState(false);
  const navbarRef = useRef(null);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
    setExpanded(false);
  }

  const handleScroll = () => {
    // Only run this logic if not on the /projects page
    if (currentPath !== '/projects') {
      setExpanded(false);
      setActiveLink('');
      const sections = ['what-we-do', 'what-we-build', 'why-choose-us', 'how-we-engage', 'who-we-are', 'contact'];
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      let isInSection = false;

      for (const section of sections) {
        const el = document.getElementById(section);
        if (el && scrollPosition >= el.offsetTop && scrollPosition < el.offsetTop + el.offsetHeight) {
          setActiveLink(section);
          isInSection = true;
          break;
        }
      }

      if (!isInSection) {
        setActiveLink(''); // Set activeLink to an empty string
      }
    }
  };

  useEffect(() => {
    if (currentPath === '/projects') {
      setActiveLink('projects');
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPath]);
  
  useEffect(() => {
    // Update activeLink based on the current path
    if (currentPath === '/projects') {
      setActiveLink('projects');
    } else if (currentPath === '/') {
      const hash = window.location.hash;
      if (hash) {
        // Find the element that corresponds to the hash
        const section = document.querySelector(hash);
        if (section) {
          setActiveLink('/');
          section.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }, [currentPath]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  
  return (
    <>
      <Navbar bg="white" expand="lg" className="fixed-top" expanded={expanded} onToggle={() => setExpanded(!expanded)}  ref={navbarRef}>
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <Navbar.Brand href="/">
          <img src={logo} alt="FGC Logo" className="img-fluid" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbar" className="mobile-nav-toggle" />

        <Navbar.Collapse id="navbar">
          <Nav className="ml-auto">
            <Nav.Link href="/#what-we-do" className={activeLink === 'what-we-do' ? 'inview navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('what-we-do')}> What We Do </Nav.Link>
            <Nav.Link href="/#what-we-build" className={activeLink === 'what-we-build' ? 'inview navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('what-we-build')}> What We Build </Nav.Link>
            <Nav.Link href="/#why-choose-us" className={activeLink === 'why-choose-us' ? 'inview navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('why-choose-us')}> Why Choose Us </Nav.Link>
            <Nav.Link href="/#how-we-engage" className={activeLink === 'how-we-engage' ? 'inview navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('how-we-engage')}> How We Engage </Nav.Link>
            <Nav.Link href="/#who-we-are" className={activeLink === 'who-we-are' ? 'inview navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('who-we-are')}> Who We Are </Nav.Link>
            <Nav.Link href="/projects" className={activeLink === 'projects' ? 'inview navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}> Projects </Nav.Link>
            <Nav.Link href="/#contact" className={activeLink === 'contact' ? 'inview navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('contact')}>
              <button className="getstarted"><span>Get in Touch</span></button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
      </Navbar>
    </>
  )
}
