const Card = ({ associatedFilters, Name, Link, delay }) => {
  return (
    <a href={Link} className="text-decoration-none" data-aos="fade-up" data-aos-delay={delay}>
      <div className="card bg-white" id="project-list-card" style={{minHeight: "250px" }}>
        {/* {Image && <img src={Image} className="card-img-top" style={{ borderRadius: "30px 30px 0px 0px" }} alt={Name} />} */}
        <div className="card-body d-flex flex-column justify-content-between">
          <div className="card-title mb-3">
            <div className="category-list" style={{ padding: "0px" }}>
              {associatedFilters.map((filter, index) => (
                <div className="category-list-item" key={index}>
                  {filter}
                </div>
              ))}
            </div>
          </div>
          <div className="card-text text-dark category-name" style={{ fontWeight: "700", paddingLeft: "4px", fontSize: "20px" }}>
            {Name}
          </div>
          <div className="card-link text-dark mt-4 align-self-start text-dark" id="project-list-link" aria-label={`Read more about ${Name}`}>
            Read More{">>>>"}
          </div>
        </div>
      </div>
    </a>
  );
}
 
export const WhatWeBuild = () => {
 
  const projectList = [
    {
      id: 0,
      Name: "Cloud Based Fixed Income Trading System",
      Link: "/projects/cloud-fixed-income-trading-system",
      associatedFilters: ["Fixed Income"],
      delay: 50,
    },
    // {
    //   id: 6,
    //   Name: "Global Corporate Action System for Prime Broker Services",
    //   Link: "/projects/corporate-action-management",
    //   associatedFilters: ["Equities", "Fixed Income"],
    //   delay: 100,
    // },
    {
      id: 7,
      Name: "Global Corporate Action Event Generation & Reporting System",
      Link: "/projects/corporate-action-events",
      associatedFilters: ["Equities", "Fixed Income"],
      delay: 100,
    },
    {
      id: 9,
      Name: "Global Asset Class Neutral Trading System",
      Link: "/projects/trade-management-system",
      associatedFilters: ["Equities", "FX", "Fixed Income", "Interest Rate"],
      delay: 150,
    },
    {
      id: 2,
      Name: "Fixed Income Electronic Trade Processing System For Clearing, Settlement and Reporting",
      Link: "/projects/electronic-post-trade-processing-system",
      associatedFilters: ["Fixed Income"],
      delay: 200,
    },
    {
      id: 5,
      Name: "Lite Equity Order Management System (OMS)",
      Link: "/projects/lite-equity-oms",
      associatedFilters: ["Equities"],
      delay: 250,
    },
    {
      id: 8,
      Name: "Global Multi-Asset Class Trade Management System",
      Link: "/projects/multi-asset-trade-management-system",
      associatedFilters: ["Equities", "FX", "Fixed Income"],
      delay: 300,
    },
  ];
 
  return (
    <section id="what-we-build" className="what-we-build pb-1">
      <div className="design-bg" data-aos="fade-up">
        <div className="container" data-aos="fade-up">
          <header className="section-header text-center" data-aos="fade-up">
            <h2>What We Build</h2>
            <p>
              We build, run and manage fintech systems. Our clients include from very large established global financial organizations to young disruptive entrepreneurial fintech firms. We have
              excellent relationships with industry leaders in the area of technology, data, analytics, trading, straight through processing, and reporting.
            </p>
          </header>
        </div>

        <div className="full-width-background" data-aos="fade-up">
          <div className="container" data-aos="fade-up">
            <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-4 mb-5 justify-content-center">
              {projectList.map((project) => (
                <div className="col" id="build-cards" key={project.id}>
                  <Card {...project} />
                </div>
              ))}
            </div>
          </div>
        </div>
 
        <div id="view-all-projects-button" className="text-center" data-aos="fade-up">
          <a href="/projects" className="btn-get-started btn-get-started-secondary scrollto d-inline-flex align-items-center justify-content-center align-self-center mt-2">
            <span>View all Projects</span>
          </a>
        </div>
      </div>
    </section>
  );
};
 
export default WhatWeBuild;