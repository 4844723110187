import whatWeDoImg from "../assets/img/what-we-do.webp";
import architectIcon from "../assets/img/architect-icon.webp";
import developIcon from "../assets/img/develop-icon.webp";
import supportIcon from "../assets/img/support-icon.webp";
import maintainIcon from "../assets/img/maintain-icon.webp";

const ProjectCard = ({ logo, number, title, para1, para2, delay, style }) => {
  // Define the style object with the dynamic background color
  const containerStyle = {
    style: style,
  };

  return (
    <div className={`col-md-6`} data-aos="fade-up" data-aos-delay={delay}>
      <div className="icon-box d-flex align-items-center" style={style}>
        <div class="container">
          <div class="row">
            <div class="col">
              <img src={logo} alt="Your Logo" class="img-fluid" />
            </div>
          </div>
        </div>

        <div class="container mt-3">
          <div class="row">
            <div class="col-2">
              <div class="row">
                <div class="col text-center">
                  <h3 class="font-weight-bold">{number}</h3>
                </div>
              </div>
            </div>

            <div class="col-10">
              <div class="row">
                <div class="col">
                  <h3>{title}</h3>
                </div>
              </div>
              <div class="row">
                <div class="col mb-3">
                  <p>{para1}</p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p>{para2}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const WhatWeDo = () => {
  return (
    <section id="what-we-do" className="what-we-do">
      <div class="container">
        <header class="section-header text-center" data-aos="fade-up">
          <h2>What We Do</h2>
          <p>
            We build, run and manage resilient and secure financial systems, faster and cheaper. Trillions of dollars' worth transactions have been executed by global financial institutions on the
            systems built by us.
          </p>
        </header>

        {/* Laptop Screen */}
        <div class="what-we-do-img desktop-only" data-aos="fade-up">
          <img src={whatWeDoImg} alt="What-We-Do" class="img-fluid" />
        </div>

        {/* Mobile Screen */}
        <div className="container p-0 mobile-only" data-aos="fade-up">
          <div id="project-cards" className="container-fluid">
            <div id="project-cards" className="row align-items-xl-center gy-5">
              <div className="col-xl-6">
                <div className="row gy-4 icon-boxes">
                  <ProjectCard
                    logo={architectIcon}
                    number="01"
                    title="Architect"
                    para1="We put in a lot of time and effort in getting the architecture right."
                    para2="Better get this right. This is what gives you the most headaches otherwise!"
                    delay={100}
                    style={{ background: "linear-gradient(122deg, #E1DCF2 0%, #C4DAF8 99.93%)" }}
                  />
                  <ProjectCard
                    logo={developIcon}
                    number="02"
                    title="Develop"
                    para1="We follow an agile scrum process with special attention to robust coding, and proven CI/CD and Devops processes."
                    para2="Get down and dirty, build it, get it done right!"
                    delay={150}
                    style={{ background: "linear-gradient(122deg, #F8DEEC -0.07%, #DEDCF2 99.95%)" }}
                  />
                  <ProjectCard
                    logo={supportIcon}
                    number="03"
                    title="Support"
                    para1="We operate and provide a 24x7 system, client and helpdesk support."
                    para2="What's the point, if you do not have guts to support what you built?"
                    delay={200}
                    style={{ background: "linear-gradient(122deg, #D7E6FF -0.05%, #FFF 100%)" }}
                  />
                  <ProjectCard
                    logo={maintainIcon}
                    number="04"
                    title="Maintain"
                    para1="We keep it running and keep making it better."
                    para2="Death, Taxes, and Bugs are facts of life. Fix them! Enhance the system constantly!"
                    delay={250}
                    style={{ background: "linear-gradient(121deg, #CAD0FF 0%, #E7E9F4 99.96%)" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeDo;
